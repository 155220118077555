
import { computed, defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { push } from '@/inc/tracking'

export default defineComponent({
  props: {
    route: {
      type: String,
      default: 'WikiSearchByAdjectives',
    },
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
    othersAdjectives: {
      type: String,
      default: '',
    },
    chromeList: {
      type: Array as () => string[],
      default: ['Option 1'],
    },
    placeholder: {
      type: String,
      default: 'Recherche par adjectif(s) (exemple: Discret, Bienveillant)',
    },
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const adjectives = ref('')
    const selectedAdjective = ref('')

    const searchAdjectives = computed(() => {
      if (adjectives.value === '') {
        return []
      }

      let matches = 0

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return props.chromeList.filter((adjective: any) => {
        if (
          adjective.name
            .toLowerCase()
            .includes(adjectives.value.toLowerCase()) &&
          matches < 10
        ) {
          matches++
          return adjective
        }
      })
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const submit = (adjective: any) => {
      selectedAdjective.value = adjective
      adjectives.value = adjective

      if (adjectives.value === route?.params?.adjectives) {
        console.log('same')
        window.location.reload()
      } else {
        if (props.othersAdjectives) {
          adjectives.value = adjectives.value + ',' + props.othersAdjectives
        }

        router.push({
          name: props.route,
          params: {
            adjectives: adjectives.value,
            category: props.category,
            list: props.list,
          },
        })
      }

      console.log('category', props.route)

      push({
        name: 'search-wiki',
        category: 'keywords-wiki',
        action: 'search',
        label: adjectives.value,
      })
    }

    return {
      adjectives,
      searchAdjectives,
      selectedAdjective,
      submit,
    }
  },
})
