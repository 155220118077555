<template>
  <form class="search" @submit.prevent="submit(adjectives)">
    <input
      type="text"
      id="search"
      autocomplete="off"
      v-model="adjectives"
      :placeholder="placeholder"
    />

    <ul class="search-dropdown" v-if="searchAdjectives.length">
      <li
        v-for="adjective in searchAdjectives"
        :key="adjective.slug"
        @click="submit(adjective.slug)"
      >
        {{ adjective.name }}
      </li>
    </ul>

    <button><SvgSprite symbol="ui-search" size="16" /></button>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { push } from '@/inc/tracking'

export default defineComponent({
  props: {
    route: {
      type: String,
      default: 'WikiSearchByAdjectives',
    },
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
    othersAdjectives: {
      type: String,
      default: '',
    },
    chromeList: {
      type: Array as () => string[],
      default: ['Option 1'],
    },
    placeholder: {
      type: String,
      default: 'Recherche par adjectif(s) (exemple: Discret, Bienveillant)',
    },
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const adjectives = ref('')
    const selectedAdjective = ref('')

    const searchAdjectives = computed(() => {
      if (adjectives.value === '') {
        return []
      }

      let matches = 0

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return props.chromeList.filter((adjective: any) => {
        if (
          adjective.name
            .toLowerCase()
            .includes(adjectives.value.toLowerCase()) &&
          matches < 10
        ) {
          matches++
          return adjective
        }
      })
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const submit = (adjective: any) => {
      selectedAdjective.value = adjective
      adjectives.value = adjective

      if (adjectives.value === route?.params?.adjectives) {
        console.log('same')
        window.location.reload()
      } else {
        if (props.othersAdjectives) {
          adjectives.value = adjectives.value + ',' + props.othersAdjectives
        }

        router.push({
          name: props.route,
          params: {
            adjectives: adjectives.value,
            category: props.category,
            list: props.list,
          },
        })
      }

      console.log('category', props.route)

      push({
        name: 'search-wiki',
        category: 'keywords-wiki',
        action: 'search',
        label: adjectives.value,
      })
    }

    return {
      adjectives,
      searchAdjectives,
      selectedAdjective,
      submit,
    }
  },
})
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  width: 100%;

  button {
    @include center-y;
    right: 5px;
    border: 0;
    background: transparent;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    svg {
      margin-top: 4px;
      fill: $tangerine;
    }
  }

  ::placeholder {
    font-size: 1.6rem;
    line-height: 1.4;
    color: $hippie-blue;
  }

  input {
    border: 0;
    padding: 1.05rem 1.2rem;
    background: $c-white !important;
    font-size: 1.6rem;
    line-height: 1.4;
    color: $hippie-blue !important;
    width: 100%;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $athens-gray inset !important;
    box-shadow: 0 0 0 1000px $athens-gray inset !important;
  }

  .search-dropdown {
    position: absolute;
    top: 4.5rem;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: white;
    list-style: none;
    z-index: 10;

    li {
      padding: 1rem;
      border-bottom: 1px solid $botticelli;
      cursor: pointer;

      &:hover {
        background-color: $botticelli;
      }
    }
  }
}
</style>
